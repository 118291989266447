<template>
  <v-dialog v-model="showRealDialogLogin" max-width="500" persistent>
    <v-card
      v-if="
        showLogin &&
          currentRecoveryPassword.currentStep == recoveryPasswordSteps.None
      "
    >
      <div class="text-right">
        <v-btn icon @click="abort"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="pa-6 pt-1">
        <div class="headline text-center mb-4">Inicio de sesión</div>
        <v-alert v-if="alert.show" dense border="left" type="warning">
          {{ alert.message }}
        </v-alert>

        <v-form
          v-model="form.isValid"
          @submit.prevent="enter"
          ref="formLogin"
          class="font-color-subtitlename"
        >
          <p><img src="assets/img/rut.svg" /> Rut (sin puntos ni guión)</p>
          <v-text-field
            autocomplete="false"
            outlined
            v-model.trim="formFields.rut"
            :label="formFields.rut | formatRut"
            required
            :rules="formFields.rutRules"
            maxlength="9"
            hint="Sin puntos ni guión"
          ></v-text-field>

          <div>
            <div class="d-inline font-color-subtitlename">
              <img src="assets/img/password.svg" /> Contraseña
            </div>
            <div class="ml-5 d-inline text-right">
              <router-link
                to="#"
                v-on:click.native="startRecoveryPassword()"
                :disabled="form.processing"
                >Recuperar Contraseña</router-link
              >
            </div>
          </div>
          <v-text-field
            :type="showPassword ? 'text' : 'password'"
            autocomplete="false"
            outlined
            v-model.trim="formFields.password"
            required
            :rules="formFields.passwordRules"
            maxlength="20"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @mousedown="showPassword = true"
            @mouseup="showPassword = false"
          ></v-text-field>

          <v-btn
            type="submit"
            class="btn-main"
            block
            :disabled="!form.isValid"
            :loading="form.processing"
            >Continuar</v-btn
          >
        </v-form>
      </div>
    </v-card>
    <v-card
      v-if="
        currentRecoveryPassword.currentStep ==
          recoveryPasswordSteps.askIdentification
      "
    >
      <div class="text-right">
        <v-btn icon @click="abort"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="pa-6 pt-1">
        <div class="headline text-center mb-4">Recuperar contraseña</div>

        <p class="text-caption text-center">
          Enviaremos un código a su email de contacto para validar la solicitud
          de cambio de contraseña.
        </p>

        <v-alert v-if="alert.show" dense border="left" type="warning">
          {{ alert.message }}
        </v-alert>

        <v-form
          v-model="form.isValid"
          @submit.prevent="prepareSendOtp"
          ref="formGetOtp"
        >
          <p><img src="assets/img/rut.svg" /> Rut (sin puntos ni guión)</p>
          <v-text-field
            autocomplete="false"
            outlined
            v-model.trim="formFields.rutRecoverPassword"
            :label="formFields.rutRecoverPassword | formatRut"
            required
            :rules="formFields.rutRecoverPasswordRules"
            maxlength="9"
            hint="Sin puntos ni guión"
          ></v-text-field>

          <v-btn
            type="submit"
            class="btn-main"
            block
            :disabled="!form.isValid"
            :loading="form.processing"
            >Solicitar código</v-btn
          >
        </v-form>
      </div>
    </v-card>
    <v-card
      v-if="currentRecoveryPassword.currentStep == recoveryPasswordSteps.askOtp"
    >
      <div class="text-right">
        <v-btn icon @click="abort"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="pa-6 pt-1">
        <div class="headline text-center mb-4">Recuperar contraseña</div>

        <v-alert v-if="alert.show" dense border="left" type="warning">
          {{ alert.message }}
        </v-alert>

        <p class="text-center">
          Ingrese el código de 6 dígitos que enviamos al correo
          <span class="text-caption font-italic">{{
            currentRecoveryPassword.email | maskEmail
          }}</span>
        </p>

        <v-form
          v-model="form.isValid"
          @submit.prevent="verifyOtp"
          ref="formVerifyOtp"
        >
          <v-text-field
            autocomplete="false"
            outlined
            v-model.trim="formFields.oneTimePassword"
            :disabled="oneTimePasswordExpired || oneTimePasswordMaxRetries"
            required
            :rules="formFields.oneTimePasswordRules"
            maxlength="6"
          ></v-text-field>
          <v-btn
            type="submit"
            class="btn-main"
            block
            :disabled="
              !form.isValid ||
                oneTimePasswordExpired ||
                oneTimePasswordMaxRetries
            "
            :loading="form.processing"
            >Continuar</v-btn
          >
        </v-form>

        <div
          class="mt-5 text-center"
          v-if="
            !form.processing &&
              !oneTimePasswordExpired &&
              !oneTimePasswordMaxRetries
          "
        >
          <router-link
            to="#"
            v-on:click.native="resendOtp()"
            :disabled="form.processing"
            >Volver a solicitar código</router-link
          >
        </div>
        <div
          class="mt-5 text-center"
          v-if="
            !form.processing &&
              !oneTimePasswordExpired &&
              !oneTimePasswordMaxRetries
          "
        >
          <router-link
            to="#"
            v-on:click.native="gotoContact()"
            :disabled="form.processing"
            >Solicitar código a otro medio</router-link
          >
        </div>

        <v-card class="mt-4" elevation="6" v-if="showMessageOtpResended">
          <v-card-text
            ><v-icon>mdi-alert-circle-outline</v-icon> Se acaba de enviar un
            nuevo código</v-card-text
          >
        </v-card>
      </div>
    </v-card>
    <v-card
      v-if="
        currentRecoveryPassword.currentStep ==
          recoveryPasswordSteps.askNewPassword
      "
    >
      <div class="text-right">
        <v-btn icon @click="abort"><v-icon>mdi-close</v-icon></v-btn>
      </div>
      <div class="pa-6 pt-1">
        <div class="headline text-center mb-4">Recuperar contraseña</div>

        <v-alert v-if="alert.show" dense border="left" type="warning">
          {{ alert.message }}
        </v-alert>

        <v-form
          v-model="form.isValid"
          @submit.prevent="changePassword"
          ref="formChangePassword"
        >
          <p><img src="assets/img/password.svg" /> Nueva Contraseña</p>
          <v-text-field
            :type="showPassword ? 'text' : 'password'"
            autocomplete="false"
            outlined
            v-model.trim="formFields.newPassword"
            required
            :rules="formFields.newPasswordRules"
            maxlength="20"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @mousedown="showPassword = true"
            @mouseup="showPassword = false"
          ></v-text-field>

          <ul class="mb-6">
            <li>Entre 8 y 20 caracteres</li>
            <li>Debe incluir mayusculas y minusculas</li>
            <li>Debe incluir dígitos</li>
            <li>Debe incluir caracteres especial</li>
          </ul>

          <p><img src="assets/img/password.svg" />Repetir Nueva Contraseña</p>
          <v-text-field
            :type="showPassword ? 'text' : 'password'"
            autocomplete="false"
            outlined
            v-model.trim="formFields.confirmNewPassword"
            required
            :rules="formFields.confirmNewPasswordRules"
            maxlength="20"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @mousedown="showPassword = true"
            @mouseup="showPassword = false"
          ></v-text-field>

          <v-btn
            type="submit"
            class="btn-main"
            block
            :disabled="!form.isValid"
            :loading="form.processing"
            >Cambiar contraseña</v-btn
          >
        </v-form>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentRecoveryPasswordMixin from "@/mixins/currentRecoveryPassword";

import accountsService from "@/services/accounts";
import challengesService from "@/services/challenges";

import sxRut from "sx-rut";

export default {
  mixins: [baseViewMixin, currentUserMixin, currentRecoveryPasswordMixin],
  props: {
    showDialogLogin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      retry:0,
      formFields: {
        rut: "",
        rutRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexRut.test(value) || "No es válido",
          (value) => sxRut.isValidRut(value) || "No es válido",
        ],
        password: "",
        passwordRules: [
          (value) => !!value || "Requerido",
          (value) => value.length >= 8 || "Mínimo 8 caracteres",
        ],
        rutRecoverPassword: "",
        rutRecoverPasswordRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexRut.test(value) || "No es válido",
          (value) => sxRut.isValidRut(value) || "No es válido",
        ],
        oneTimePassword: "",
        oneTimePasswordRules: [
          (value) => !!value || "Requerido",
          (value) =>
            this.allConstants.regex.regexDigits.test(value) || "No es válido",
          (value) => value.length >= 6 || "Mínimo 6 dígitos",
        ],
        newPassword: "",
        newPasswordRules: [
          (value) => !!value || "Requerido",
          (value) => value.length >= 8 || "Mínimo 8 caracteres",
        ],
        confirmNewPassword: "",
        confirmNewPasswordRules: [
          (value) => !!value || "Requerido",
          (value) => value.length >= 8 || "Mínimo 8 caracteres",
          (value) =>
            this.formFields.newPassword == value || "Claves no coinciden",
        ],
      },
      showPassword: false,
      showLogin: true,
      showMessageOtpResended: false,
      oneTimePasswordExpired: false,
      oneTimePasswordMaxRetries: false,
    };
  },
  watch: {
    "formFields.rutRecoverPassword": function() {
      this.hideAlert();
    },
    "formFields.oneTimePassword": function() {
      this.hideAlert();
    },
    "formFields.newPassword": function() {
      this.hideAlert();
    },
  },
  computed: {
    showRealDialogLogin() {
      return this.showDialogLogin;
    },
  },
  methods: {
    async enter() {
      this.hideAlert();
      this.initSignalProcessing();

      const loginResult = await this.internalLogin(
        this.formFields.rut,
        this.formFields.password
      );

      if (
        loginResult.resultCode ==
          this.allConstants.security.loginResultCode.WrongPassword ||
        loginResult.resultCode ==
          this.allConstants.security.loginResultCode.CredentialDoesNotExist
      ) {
        this.showAlert("Rut o contraseña incorrecta");
      }

      if (
        loginResult.resultCode ==
        this.allConstants.security.loginResultCode.CredentialLocked
      ) {
        this.showAlert("Cuenta bloqueada por exceso de intentos fallidos");
        this.formFields.rut = "";
        this.formFields.password = "";
      }

      if (
        loginResult.resultCode ==
        this.allConstants.security.loginResultCode.CredentialExpired
      ) {
        this.showAlert("Cuenta no existe o se encuentra expirada");
        this.formFields.rut = "";
        this.formFields.password = "";
      }

      if (
        loginResult.resultCode ==
          this.allConstants.security.loginResultCode.Success ||
        loginResult.resultCode ==
          this.allConstants.security.loginResultCode
            .SuccessAndMustChangePassword
      ) {
        this.internalPostLogin();
      }

      this.stopSignalProcessing();
    },
    async prepareSendOtp() {
      this.hideAlert();
      this.initSignalProcessing();

      const accountFindResult = await this.findAccountV2(
        this.formFields.rutRecoverPassword
      );

      if (!accountFindResult) {
        this.showAlert("No encontramos su rut en nuestros registros");
        this.stopSignalProcessing();
        return;
      }

      const actualRecoveryPassword = this.currentRecoveryPassword;
      await this.createOrUpdateRecoveryPassword(actualRecoveryPassword);

      await this.sendOtpV2();
      
     
    },
    async sendOtp() {
      const message = {
        messagesInfo: [
          {
            type: "email",
            address: this.currentRecoveryPassword.email,
            attributes: [
              {
                key: "Nombre",
                value: this.currentRecoveryPassword.name,
              },
            ],
          },
        ],
      };

      const sendOtpResult = await challengesService.sendOtp(message);

      const actualRecoveryPassword = this.currentRecoveryPassword;
      actualRecoveryPassword.transactionId = sendOtpResult.data.transactionId;
      await this.createOrUpdateRecoveryPassword(actualRecoveryPassword);
    },
    async sendOtpV2() {
      try {
        this.retry++;
        const sendOtpResult = await accountsService.sendOtp(
        this.formFields.rutRecoverPassword
        );
        const actualRecoveryPassword = this.currentRecoveryPassword;
        actualRecoveryPassword.transactionId = sendOtpResult.data.transactionId;
        actualRecoveryPassword.email = sendOtpResult.data.email;

        await this.createOrUpdateRecoveryPassword(actualRecoveryPassword);
        this.currentRecoveryPasswordGoForeward();
        this.stopSignalProcessing();
       } catch (error) {
        if(this.retry ==5)
        {
           this.$store.dispatch(
          "notifications/addError",
          "Servicio no disponible. Vuelva a intentar"
          );
          this.retry=0;
          this.stopSignalProcessing();
        }
        else
        {
          setTimeout(() => (this.sendOtpV2()), 2000);          
        }        
      }
      
    },
    async resendOtp() {
      this.hideAlert();
      this.initSignalProcessing();

      try {
        await this.sendOtpV2();
        this.showMessageOtpResended = true;
        setTimeout(() => (this.showMessageOtpResended = false), 4000);
      } finally {
        this.stopSignalProcessing();
      }
    },
    async verifyOtp() {
      this.hideAlert();
      this.initSignalProcessing();

      const verifyOtpData = {
        identificationNumber: this.formFields.rutRecoverPassword,
        code: this.formFields.oneTimePassword,
        transactionId: this.currentRecoveryPassword.transactionId,
      };

      //const verifyOtpResult = await challengesService.verifyOtp(verifyOtpData);
      const verifyOtpResult = await accountsService.verifyOtp(verifyOtpData);

      if (verifyOtpResult.data.status == this.allConstants.otp.status.invalid) {
        this.showAlert("El código ingresado no es correcto");
        this.stopSignalProcessing();
        return;
      }

      if (verifyOtpResult.data.status == this.allConstants.otp.status.expired) {
        this.oneTimePasswordExpired = true;
        this.showAlert("Código expirado");
        this.stopSignalProcessing();
        return;
      }

      if (
        verifyOtpResult.data.status == this.allConstants.otp.status.maxRetries
      ) {
        this.oneTimePasswordMaxRetries = true;
        this.showAlert("Código bloqueado por exceso de intentos fallidos");
        this.stopSignalProcessing();
        return;
      }

      const actualRecoveryPassword = this.currentRecoveryPassword;
      actualRecoveryPassword.credentialId = verifyOtpResult.data.credentialId;
      actualRecoveryPassword.token = verifyOtpResult.data.token;
      await this.createOrUpdateRecoveryPassword(actualRecoveryPassword);

      this.currentRecoveryPasswordGoForeward();

      this.stopSignalProcessing();
    },
    async changePassword() {
      this.hideAlert();
      this.initSignalProcessing();

      const checkIsValidPasswordResult = await accountsService.checkIsValidPasswordPattern(
        this.formFields.newPassword
      );

      if (!checkIsValidPasswordResult.data.isValid) {
        this.showAlert("La nueva Contraseña no cumple con los requerimientos");
        this.stopSignalProcessing();
        return;
      }

      const changePasswordData = {
        credentialId: this.currentRecoveryPassword.credentialId,
        token: this.currentRecoveryPassword.token,
        newPassword: this.formFields.newPassword,
      };

      let forceChangePasswordResult;
      try {
        forceChangePasswordResult = await accountsService.forceChangePassword(
          changePasswordData
        );
      } catch {
        this.$store.dispatch(
          "notifications/addError",
          "El tiempo de cambiar la Contraseña expiro."
        );

        this.stopSignalProcessing();
        this.clearRecoveryPassword();
        this.showLogin = true;
        return;
      }

      if (
        forceChangePasswordResult.data.resultCode ==
        this.changePasswordResultCode.NewPasswordUsedPreviously
      ) {
        this.showAlert(
          "La nueva contraseña ya fue usada con anterior. Ingrese una distinta."
        );
        this.stopSignalProcessing();
        return;
      }

      this.$store.dispatch(
        "notifications/addInfo",
        "Contraseña cambiada exitosamente."
      );

      this.stopSignalProcessing();
      this.clearRecoveryPassword();
      this.showLogin = true;
    },
    async findAccount(identificationNumber) {
      try {
        const getAccountResult = await accountsService.getByIdentification(
          identificationNumber
        );
        if (getAccountResult.data && getAccountResult.data.id != "") {
          return getAccountResult.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
    async findAccountV2(identificationNumber) {
      try {
        const getAccountResult = await accountsService.exists(
          identificationNumber
        );
        if (getAccountResult.status == 200) {
          return {};
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    },
    abort() {
      this.formFields.rut = "";
      this.formFields.password = "";
      this.formFields.rutRecoverPassword = "";
      this.formFields.oneTimePassword = "";
      this.formFields.newPassword = "";
      this.formFields.confirmNewPassword = "";

      this.oneTimePasswordExpired = false;
      this.oneTimePasswordMaxRetries = false;

      this.clearRecoveryPassword();

      this.showLogin = true;

      this.hideAlert();
      this.$emit("abort");
    },
    gotoContact() {
      this.clearRecoveryPassword();
      this.$emit("abort");
      this.gotoView("contacto");
    },
  },
};
</script>

<style lang="scss" scoped>
.headline {
  color: #199bca !important;
}
.font-color-subtitlename {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  color: #555;
}
</style>
